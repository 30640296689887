import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class LeadIpadService {

  public state$ = new BehaviorSubject<any>([]);
  // public clubsList: Observable<any>;

  private selectedClubId = new Subject<string>();
  private selectedClubObject = new Subject<{}>();
  private clubFound = new Subject<boolean>();

  public clubsList$: any = this.state$.asObservable();
  // Observable string streams
  selectedClubId$ = this.selectedClubId.asObservable();
  selectedClubObject$ = this.selectedClubObject.asObservable();
  clubFound$ = this.clubFound.asObservable();

  constructor(private http: HttpClient) { }

  /**
   *
   * Observable methods
   *
   */
  updateSelectedClubObject(clubObj) {
    this.selectedClubObject.next(clubObj);
  }

  updateClubFound(isValidClub) {
    this.clubFound.next(isValidClub);
  }

  updateClubData(club) {
    this.state$.next(club);
  }

  updateSelectedClubModel(data: string) {
    this.selectedClubId.next(data);
  }

  changeState(myChange) {
    this.state$.next(myChange);
  }

  createFreeTrial(formObj) {
    return this.http.post('createTrial', formObj)
      .map(
        res => {
          return res;
        }).catch((error: any) => this.handleError(error));
  }

  createLead(formObj) {
    return this.http.post('createSweepstakes', formObj).map(
      res => {
        return res;
        // return res['body'];
      })
      .catch((error: any) => this.handleError(error));
  }

  validateEmailOnKickbox(email) {
    const emailValidateEndpoint = `validate-email/${email}`;

    return this.http.get<any>(emailValidateEndpoint).map(
      res => {
        return res;
      }).catch((error: any) => this.handleError(error));

  }


  /**
   *
   * Handle any Failed API method
   *
   */

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return Observable.throw(errorMessage);
  }

}
