// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  leadApi: {
    keyName: 'x-api-key',
    keyValue: 'NnvNkyqsxh8iGtho4mBjJ6JocxGPx2PF4LTq6aPA',
    contentType: 'application/json',
    cmsUrl: 'https://qa.api.sls.blinkfitness.com/content/dev/web/',
    baseUrl: 'https://getipadlead.api.sls.blinkfitness.com/web-blink.moso-api/'
  },
  validateEmailApi: {
    keyName: 'x-api-key',
    keyValue: 'BRCjHwPk5L7YQ1G7KpuLy6SbBloGjIz6adSL1B9u',
    contentType: 'application/json',
    baseUrl: 'https://blink-services.api.sls.blinkfitness.com/dev/'
  }
};
