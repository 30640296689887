import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
import { LeadIpadService } from '../services/lead-ipad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from '../../core/shared.service';
import { NgForm } from '@angular/forms';
import { LocationService } from '../services/location.service';
import 'rxjs/add/operator/toPromise';

@Component({
  selector: 'app-sweeps',
  templateUrl: './sweeps.component.html',
  styleUrls: ['./sweeps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SweepsComponent implements OnInit {
  locations: any;
  lockedClub = false;
  facilities = this.locationService.locationsData$;
  clubUrl: BehaviorSubject<any[]> = new BehaviorSubject(null);
  private clubsData: any = [];
  clubFound = false;
  franchise = false;
  sweepsFormModel: any = {};
  selectedClubObject: any = {};
  private url_param_club_name: string = undefined;
  ModalObject: any = {};
  tabHeading: String = 'Win a free membership';
  @Output() tabHeadingEvent = new EventEmitter<string>();
  @ViewChild('sweepsForm') sweepsForm: NgForm;

  constructor(
    private _route: ActivatedRoute,
    private shared: SharedService,
    private clubsApi: LeadIpadService,
    private locationService: LocationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.shared.showLoader();
    this.locationService.getLocationsData().then(
      (res) => {
        this.shared.hideSwal();
        this.locationService._locationsData.next(res);
      },
      (err) => console.error(err)
    );
    this.getLocations();
    const param = this._route.snapshot.paramMap.get('club_name');
    if (param) {
      this.lockedClub = true;
      this.url_param_club_name = param;
      this.setDefaultClubField(param);
    }
    this.clubsApi.selectedClubId$.subscribe(data => {
      this.sweepsFormModel.selectedClubValue = data;
      this.getClubData(data);
      this.changeTabNameAndHeading();
    });
  }

  clearSelect() {
    const selects: HTMLSelectElement = <HTMLSelectElement>document.getElementsByClassName('selectedClub');
    // To resolve the Safari Dropdown Bug
    if (!this.lockedClub) {
      setTimeout(() => {
        selects[0].selectedIndex = -1;
        selects[1].selectedIndex = -1;
        selects[2].selectedIndex = -1;
      }, 100);
    }
  }

  getLocations() {
    this.facilities.subscribe(res => {
      if (res) {
        this.locations = res.map((loc: any) => {
          loc.locationName = loc.title.rendered.includes('Blink') ? loc.title.rendered : 'Blink ' + loc.title.rendered;
          return loc;
        });

        this.clearSelect();
      }
    });
  }

  getClubData(name) {
    this.facilities.subscribe(res => {
      if (res) {
        res.forEach(item => {
          if (item.acf.title === name) {
            this.selectedClubObject = item;
          }
        });
      }
    });
  }

  resetCustomFieldsForm() {
    if (this.clubFound) {
      const club = this.sweepsFormModel.selectedClubValue;
      this.resetSweepsForm();
      setTimeout(() => {
        this.sweepsFormModel.selectedClubValue = club;
        this.clubsApi.updateSelectedClubModel(club);
        this.clubFound = true;
        this.clubsApi.updateClubFound(this.clubFound);
      }, 0);
    } else {
      this.resetSweepsForm();
    }
  }

  setDefaultClubField(urlParam) {
    this.facilities.subscribe(res => {
      if (res) {
        const result = res.find((i) => {
          return i.slug === urlParam;
        });
        if (result) {
          this.sweepsFormModel.selectedClubValue = result.acf.title;
          this.clubsApi.updateSelectedClubModel(result.acf.title);
          this.clubFound = true;
          this.clubsApi.updateClubFound(this.clubFound);
        } else {
          this.router.navigate(['/404']);
        }
      }
    });
  }

  /*
    Set default selected Club if Url has value of club
  */

  setDefaultSelectedClub(club_name) {
    const clubs = this.clubsData;
    for (const c of clubs) {
      if (c['shortName'].toLowerCase() === club_name.toString().toLowerCase()) {
        this.clubsApi.updateSelectedClubModel(c['clubId']);
        this.clubFound = true;
        this.clubsApi.updateClubFound(this.clubFound);
        break;
      }
    }
  }

  /*
    Update other Components select value here
  */
  changeSelectedClub(newValue) {
    this.clubsApi.updateSelectedClubModel(newValue);
  }

  resetSweepsForm() {
    this.sweepsForm.resetForm();
    if (this.url_param_club_name !== undefined) {
      setTimeout(() => {
        this.setDefaultClubField(this.url_param_club_name);
      }, 100);
    }
  }

  /**
   *
   * This @function would be when the selected Club is Sweeps or presales club
   *
   */
  submitSweepsForm(form_data) {
    this.shared.showLoader();
    this.clubsApi.createLead(form_data).subscribe(
      data => {
        this.shared.hideSwal();
        if (data['message']) {
          const bodyHtml = `<p>We will send you an email notifying you when your club opens!</p>`;
          this.shared.showSuccess(bodyHtml);
          this.resetSweepsForm();
          // Reset Form here
        } else {
          this.displayErrorDialog();
        }
      },
      error => {
        this.shared.hideSwal();
        this.displayErrorDialog();
        console.error(error);
      });
  }


  /**
   *
   * This @function would be when the selected Club is Opened club
   *
   */

  submitTrialForm(form_data) {
    this.shared.showLoader();

    this.clubsApi.createFreeTrial(form_data).subscribe(
      data => {
        this.shared.hideSwal();
        if (data['message']) {
          this.showTrialSuccess(form_data.firstName);
          // Reset Form here
          this.resetSweepsForm();
        } else {
          this.displayErrorDialog();
        }
      },
      error => {
        this.shared.hideSwal();
        this.displayErrorDialog();
        console.error(error);
      });
  }

  showTrialSuccess(name) {
    const details = this.selectedClubObject.acf;

    const bodyHtml = `<h3 class="name">Thanks, ${name}</h3>
      <p>A free 1-day trial is waiting for you in your inbox.<br> Print it out or show it on your phone to an associate.</p>
      <h3> redeem at: </h3>
      <p>${details.title}</p>
        <p>${details.address.street + '' + details.address.street2}</p>
        <p>${details.address.city + ', ' + details.address.state + ' ' + details.address.zip}</p>
        <p${details.contacts.phone}</p>
        <p>hours.</p>
        <p>Monday-Thursday: 5am to 11pm</p>
        <p>Friday: 5am to 10pm</p>
        <p>Saturday-Sunday: 7am to 7pm</p>
        <br>`;

    this.shared.showSuccess(bodyHtml);
  }

  displayErrorDialog() {
    const errorMsg = 'Looks like there was an error. Take a deep breath and try again.';
    this.shared.showError(errorMsg);
  }

  changeTabNameAndHeading() {
    if (this.selectedClubObject.acf && this.selectedClubObject.acf.type === 'sweepstakes') {
      this.tabHeading = 'WIN A FREE MEMBERSHIP';
      this.tabHeadingEvent.emit('Sweeps');
      this.franchise = false;
    } else {
      this.tabHeading = 'GET YOUR FREE TRIAL TODAY';
      this.tabHeadingEvent.emit('Trial');
      this.franchise = true;
    }
  }

  submitLead() {
    if (this.selectedClubObject.acf.type === 'sweepstakes') {
      const data = {
        email: this.sweepsFormModel.email,
        facilityId: this.selectedClubObject.acf.moso_id,
        firstName: this.sweepsFormModel.firstname,
        lastName: this.sweepsFormModel.lastname,
        facilityName: this.selectedClubObject.acf.title,
        facilityState: this.selectedClubObject.acf.address.state
      };
      this.submitSweepsForm(data);
    } else {
      const data = {
        email: this.sweepsFormModel.email,
        facilityId: this.selectedClubObject.acf.moso_id,
        firstName: this.sweepsFormModel.firstname,
        lastName: this.sweepsFormModel.lastname,
        facilityName: this.selectedClubObject.acf.title,
        facilityAddress: this.selectedClubObject.acf.address.street + this.selectedClubObject.acf.address.street2,
        facilityCity: this.selectedClubObject.acf.address.city,
        facilityState: this.selectedClubObject.acf.address.state,
        facilityZip: this.selectedClubObject.acf.address.zip,
        facilityPhone: this.selectedClubObject.acf.contacts.phone,
        facilityCMSSlug: this.selectedClubObject.slug
      };
      this.submitTrialForm(data);
    }
  }

  invalidEmailAddressMsg(email) {
    const errorMsg = `
                      Unfortunately, the email you entered ${email} appears to be invalid.
                      Please check the spelling of the email and try again.
                      `;
    this.shared.showError(errorMsg);
  }

  validateEmailAddress() {
    const email = this.sweepsFormModel.email;
    this.clubsApi.validateEmailOnKickbox(email).subscribe((data) => {

      if (data['success']) {
        if (data['result'] === 'unknown' || data['result'] === 'undeliverable' || data['reason']
          === 'invalid_domain' || data['reason'] === 'invalid_email' || data['reason'] === 'rejected_email') {
          // display Email invalid message
          this.invalidEmailAddressMsg(this.sweepsFormModel.email);

        } else {
          // Submit the Form here
          const formStatus = (data['result'] === 'deliverable') ? 'Successful' : 'Unknown';
          this.submitLead();
        }

      } else {
        console.log('Error in email validate API');
        // triggerAnalytics( leadCapture.formData()['facilityId'], submitionType, "API limit reached" );
        this.displayErrorDialog();
      }

    }, error => {
      this.displayErrorDialog();
    }
    );
  }
}



