import { Component, OnInit } from '@angular/core';
import { LeadIpadService } from '../services/lead-ipad.service';
import { Observable } from 'rxjs/Observable';
import { LocationService } from '../services/location.service';
import { SharedService } from '../../core/shared.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit {
  locations: any;
  facilities = this.locationService.locationsData$;
  private clubsData = [];
  mapsFormModel: any = {};
  selectedClubObject = {};
  private clubs$: Observable<any>;
  clubFound = false;
  floor_plan: string;

  constructor(
    private clubsApi: LeadIpadService,
    private locationService: LocationService
  ) {
    this.getMapData(this.mapsFormModel);
  }

  ngOnInit() {
    this.getLocations();
    this.clubsApi.clubsList$.subscribe(data => {
      this.clubsData = data;
    });

    this.clubsApi.selectedClubId$.subscribe(data => {
      this.mapsFormModel.selectedClubValue = data;
      // this.findSelectedClub(data);
      this.getMapData(data);
    });

    this.clubsApi.clubFound$.subscribe(data => {
      this.clubFound = data;
    });

  }

  getLocations() {
    this.facilities.subscribe(res => {
      if (res) {
        this.locations = res.map((loc: any) => {
          loc.locationName = loc.title.rendered.includes('Blink') ? loc.title.rendered : 'Blink ' + loc.title.rendered;
          return loc;
        });
      }
    });
  }

  changeSelectedClub(newValue) {
    this.clubsApi.updateSelectedClubModel(newValue);
    this.getMapData(newValue);
  }

  getMapData(name) {
    this.facilities.subscribe(res => {
      if (res) {
        res.forEach(item => {
          if (item.acf.title === name) {
            this.floor_plan = item.acf.floor_plan.floor_plan_image;
          }
        });
      }
    });
  }

  findSelectedClub(clubId: String) {
    this.selectedClubObject = {};
    if (clubId) {

      for (const obj of this.clubsApi.clubsList$) {
        if (obj.clubId === clubId) {
          this.selectedClubObject = obj;
          break;
        }
      }
    }
    this.clubsApi.updateSelectedClubObject(this.selectedClubObject);

    return this.selectedClubObject;
  }
}
