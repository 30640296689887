import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './shared.service';
import { P404Component } from '../other/404.component';

@NgModule({
  declarations: [
    P404Component
],
  imports: [
    CommonModule
  ],
  providers: [SharedService]
})
export class SharedModule { }
