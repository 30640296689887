import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IpadLeadComponent } from './ipad-lead/ipad-lead.component';
import { IpadLeadModule } from './ipad-lead/ipad-lead.module';
import { P404Component } from './other/404.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'getipadlead',
    pathMatch: 'full'
  },
  {
    path: 'getipadlead',
    component: IpadLeadComponent
  },
  {
    path: 'getipadlead/:club_name',
    component: IpadLeadComponent
  },
  {
    path: '404',
    component: P404Component
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    [RouterModule.forRoot(appRoutes)],
    IpadLeadModule
  ],
  exports: [
    RouterModule
    // IpadLeadModule
  ],
  declarations: []
})
export class AppRoutingModule { }
