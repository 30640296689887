import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LocationService {
  public _locationsData: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public readonly locationsData$: Observable<any[]> = this._locationsData.asObservable().pipe(distinctUntilChanged());

  constructor(
    private httpClient: HttpClient
  ) {
    // this.locationsData();
  }

  /**
   * @argument locations
   *
   */
  getLocationsData(): any {
    return new Promise((resolve) => {
      this.httpClient.get<any[]>(`facilities?per_page=100`).subscribe((res) => {
        resolve(res);
      });
    });
  }

  // locationsData(): void {
  //   this.shared.showLoader();
  //   this.httpClient.get<any[]>(`facilities?per_page=10`).subscribe(res => {
  //     this.shared.hideSwal();
  //     // const select: HTMLSelectElement = <HTMLSelectElement>document.getElementById('selectedClubValue');
  //     const selects: HTMLSelectElement = <HTMLSelectElement>document.getElementsByClassName('selectedClub');

  //     this._locationsData.next(res);

  //     // To resolve the Safari Dropdown Bug
  //     const param = this._route.snapshot.paramMap.get('club_name');
  //     if (!param) {
  //       setTimeout(() => {
  //         selects[0].selectedIndex = 3;
  //         selects[1].selectedIndex = 2;
  //         selects[2].selectedIndex = -1;
  //       }, 100);
  //     }
  //   });
  // }
}

