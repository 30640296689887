import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { IpadLeadComponent } from './ipad-lead.component';
import { SweepsComponent } from './sweeps/sweeps.component';
import { MapsComponent } from './maps/maps.component';
import { TourComponent } from './tour/tour.component';
import { LeadIpadService } from './services/lead-ipad.service';
import { SharedModule } from '../core/shared.module';
import { SharedService } from '../core/shared.service';
import { JwplayerComponent } from './jwplayer/jwplayer.component';
import { LocationService } from './services/location.service';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { ArraySortPipe } from '../core/sort-pipe/sort-pipe';

@NgModule({
  declarations: [
    IpadLeadComponent,
    SweepsComponent,
    MapsComponent,
    TourComponent,
    JwplayerComponent,
    ArraySortPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
    AngularFontAwesomeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [LeadIpadService, SharedService, LocationService]
})
export class IpadLeadModule { }
