import { Component, OnInit } from '@angular/core';
import { LeadIpadService } from '../services/lead-ipad.service';
import { Observable } from 'rxjs/Observable';
import { LocationService } from '../services/location.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit {
  locations: any;
  facilities = this.locationService.locationsData$;
  private clubsData = [];
  tourFormModel: any = {};
  selectedClubObject = {};
  private clubs$: Observable<any>;
  clubFound = false;
  drone_tour_img: BehaviorSubject<string> = new BehaviorSubject(null);
  drone_tour_video: BehaviorSubject<string> = new BehaviorSubject(null);
  player;

  constructor(
    private clubsApi: LeadIpadService,
    private locationService: LocationService
  ) {
    this.getTourData(this.tourFormModel);
  }

  ngOnInit() {
    this.getLocations();
    this.clubsApi.clubsList$.subscribe(data => {
      this.clubsData = data;
    });

    this.clubsApi.selectedClubId$.subscribe(data => {
      this.tourFormModel.selectedClubValue = data;
      // this.findSelectedClub(data);
      this.getTourData(data);
    });

    this.clubsApi.clubFound$.subscribe(data => {
      this.clubFound = data;
    });
  }

  getLocations() {
    this.facilities.subscribe(res => {
      if (res) {
        this.locations = res.map((loc: any) => {
          loc.locationName = loc.title.rendered.includes('Blink') ? loc.title.rendered : 'Blink ' + loc.title.rendered;
          return loc;
        });
      }
    });
  }

  changeSelectedClub(newValue) {
    this.clubsApi.updateSelectedClubModel(newValue);
    this.getTourData(newValue);
  }

  getTourData(name) {
    this.facilities.subscribe(res => {
      if (res) {
        res.forEach(item => {
          if (item.acf.title === name && item.acf.marketing_block.length > 0) {
            this.drone_tour_img.next(item.acf.marketing_block[0].acf.thumbnail.desktop.url);
            this.drone_tour_video.next(item.acf.marketing_block[0].acf.video);
          } else if (item.acf.title === name && !item.acf.marketing_block) {
            this.drone_tour_img.next(null);
            this.drone_tour_video.next(null);
          }
        });
      }
    });
  }

  findSelectedClub(clubId: String) {
    this.selectedClubObject = {};
    if (clubId) {
      for (const obj of this.clubsApi.clubsList$) {
        if (obj.clubId === clubId) {
          this.selectedClubObject = obj;
          break;
        }
      }
    }
    this.clubsApi.updateSelectedClubObject(this.selectedClubObject);
    return this.selectedClubObject;
  }

  onPlayerReady(player) {
    this.player = player;
  }

  onClose() {
    this.player.pause();
    this.player.seekTime(0);
  }
}
